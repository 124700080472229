import { Component, Vue } from 'vue-property-decorator';

@Component
export default class RoutingMixin extends Vue {
  public generateApiRoute(path: string, queryParameters: any = {}): string {
    const apiUrl = new URL(path, process.env.VUE_APP_API_URL);

    if (null !== queryParameters) {
      for (let parameterKey of Object.keys(queryParameters)) {
        apiUrl.searchParams.append(parameterKey, queryParameters[parameterKey]);
      }
    }

    return apiUrl.href;
  }
}
